import {ResponsiveLine} from "@nivo/line";

export default function Graph({ranks, ranknames}) {
    return (
        <div className="flex w-full justify-center pt-3">
            <div className="justify-center bg-main shadow-main w-[50%] px-5 py-3 shadow-lg rounded-lg">
                <h2 className="text-3xl font-bold">Verlauf des Ranges</h2>
                <div className="block chat:hidden">
                    {ranks.length > 0 ?
                        ranks.map((rank) => (
                            <div key={rank.day} className="flex justify-center items-center gap-2">
                                <span>Tag {rank.day}</span>
                                <h2 className="text-xl font-bold text-primary">{ranknames.find((rankname) => rankname.position === rank.position)?.name}</h2>
                            </div>
                        )) :
                        <h2 className="text-xl pt-3">Es wurden keine Einträge zum Verlauf des Ranges gefunden.</h2>}
                </div>
                <div className="hidden chat:block">
                    {ranks.length > 0 ?
                        <LineChart ranks={ranks} ranknames={ranknames}
                                   className="aspect-[16/9] w-full"/> :
                        <h2 className="text-xl pt-3">Es wurden keine Einträge zum Verlauf des Ranges gefunden.</h2>}
                </div>
            </div>
        </div>
    );
}

function LineChart(props) {
    const {ranks, ranknames} = props;

    const data = [];

    ranks.forEach((rank) => {
        data.push({
            x: rank.day,
            y: rank.position,
        });
    });

    const marginLeft = (ranknames.sort((a, b) => b.name.length - a.name.length)[0].name.length) * 8;

    return (
        <div {...props}>
            <ResponsiveLine
                data={[
                    {
                        id: "Rank",
                        data: data,
                    },
                ]}
                enableGridX={false}
                enableCrosshair={false}
                margin={{top: 50, right: 20, bottom: 50, left: marginLeft}}
                xScale={{
                    type: "point",
                    min: "auto",
                    max: 7
                }}
                yScale={{
                    type: "linear",
                    min: 0,
                    max: ranknames.length - 1
                }}
                axisTop={null}
                axisRight={null}
                axisBottom={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: "",
                    legendOffset: 45,
                    legendPosition: "middle",
                    format: (d) => {
                        return "Tag " + d;
                    }
                }}
                axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    tickValues: ranknames.map((rank) => rank.position),
                    legend: "",
                    legendOffset: -45,
                    legendPosition: "middle",

                    format: (d) => {
                        return ranknames.find((rank) => rank.position === d)?.name;
                    },
                }}
                colors={{scheme: "greys"}}
                pointSize={5}
                pointColor={{
                    from: "color",
                    modifiers: [["darker", 0.2]],
                }}
                pointBorderWidth={2}
                pointBorderColor={{
                    from: "color",
                    modifiers: [["darker", 0.2]],
                }}
                pointLabelYOffset={-12}
                tooltip={({point}) => {
                    return (
                        <div
                            className="bg-main p-3 rounded-lg border-2 border-white border-opacity-20 bg-opacity-90">
                            <p>Tag {point.data.x}</p>
                            <h2 className="text-xl font-bold">{ranknames.find((rank) => rank.position === point.data.y)?.name}</h2>
                        </div>
                    );
                }}
                gridYValues={ranknames.map((rank) => rank.position)}
                useMesh={true}
                legends={[]}
                theme={{
                    tooltip: {
                        container: {
                            fontSize: "12px",
                        },
                    },
                    grid: {
                        line: {
                            stroke: "rgba(255,255,255,0.5)",
                            strokeDasharray: "1",
                        },
                    },
                    axis: {
                        ticks: {
                            text: {
                                fill: "#ffffff",
                                fontSize: "14px",
                            },
                        }
                    },
                }}
                role="application"
            />
        </div>
    );
}