import {useState} from "react";

export default function BackgroundImage() {
    return (
        <div className="flex flex-row fixed top-0 left-0 z-[-1] w-full overflow-hidden opacity-20">
            <BackgroundLazyImage
                className="background-image w-[50%] max-h-screen overflow-hidden object-cover object-top"
                src="/img/veni_left_big.png" placeholderSrc="/img/veni_left_small.png" alt="veni left" loading="lazy"/>
            <BackgroundLazyImage
                className="background-image w-[50%] max-h-screen overflow-hidden object-cover object-top"
                src="/img/veni_right_big.png" placeholderSrc="/img/veni_right_small.png" alt="veni right"
                loading="lazy"/>
        </div>
    )
}

export function BackgroundLazyImage(props) {
    const {src, placeholderSrc, alt, className} = props;
    const [loaded, setLoaded] = useState(false);

    return (
        <>
            {!loaded &&
                <img className={className} src={placeholderSrc} alt={alt}/>
            }
            <img className={className}
                 style={loaded ? {} : {display: "none"}}
                 src={src} alt={alt}
                 onLoad={() => setLoaded(true)}/>
        </>
    );
}