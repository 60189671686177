import Navigation from "../components/navigation";
import BackgroundImage from "../components/background-image";

export default function FAQ({currentChallenge, challenges}) {
    const currentTime = new Date().getTime();

    let pastChallenges = challenges.filter(value => value.startDate < currentTime);

    if (!pastChallenges.includes(currentChallenge)) {
        pastChallenges.push(currentChallenge);
    }

    return (
        <div className="flex flex-col gap-10 justify-self-center text-center">
            <Navigation currentChallenge={currentChallenge} pastChallenges={pastChallenges} history={true}/>
            <h1 className="text-6xl font-bold mb-2 text-primary font-title tracking-wide leading-7 -mt-10">FAQ</h1>
            <div className="flex flex-col gap-10">
                <div className="w-fit m-auto bg-main px-5 py-4 shadow-lg shadow-main rounded-lg max-w-[900px]">
                    <h2 className="inline-flex text-3xl font-bold items-center mb-2">Was ist das Ziel von Veni?</h2>
                    <p className="overflow-auto">Das Ziel von Veni ist es, in verschiedensten Spielen, wie zum Beispiel
                        League of Legends, mithilfe von Pros, Coaches und anderen Creators den höchst möglichen Rang zu
                        erreichen und Erfahrungen zu sammeln.</p>
                </div>
                <div className="w-fit m-auto bg-main px-5 py-4 shadow-lg shadow-main rounded-lg max-w-[900px]">
                    <h2 className="inline-flex text-3xl font-bold items-center mb-2">Welche Spiele werden gespielt?</h2>
                    <p className="overflow-auto">Gespielte Spiele, derzeit laufende Spiele und angekündigte Spiele
                        kannst Du im Dropdown Menü finden. Kommende Spiele werden einige Tage vor dem Start der
                        Challenge-Zeit angekündigt.</p>
                </div>
                <div className="w-fit m-auto bg-main px-5 py-4 shadow-lg shadow-main rounded-lg max-w-[900px]">
                    <h2 className="inline-flex text-3xl font-bold items-center mb-2">Mit wem spielt er zusammen?</h2>
                    <p className="overflow-auto">Veni wird mithilfe von verschiedenen Profi-Spielern, Coaches und
                        anderen
                        Creators versuchen die Rangliste nach oben zu steigen.</p>
                </div>
                <div className="w-fit m-auto bg-main px-5 py-4 shadow-lg shadow-main rounded-lg max-w-[900px]">
                    <h2 className="inline-flex text-3xl font-bold items-center mb-2">Wie lange spielt Veni pro Tag im
                        Durchschnitt?</h2>
                    <p className="overflow-auto">Die tatsächlich gespielte Zeit kann von Spiel zu Spiel variieren.
                        Außerdem gehören auch Fort- und Weiterbildungen außerhalb des Spieles zum Tagesablauf
                        eines Pros.</p>
                </div>
                <div className="w-fit m-auto bg-main px-5 py-4 shadow-lg shadow-main rounded-lg max-w-[900px]">
                    <h2 className="inline-flex text-3xl font-bold items-center mb-2">Passt Veni auch seinen
                        Tagesrhythmus an?</h2>
                    <p className="overflow-auto">Ja, Veni wird verschiedenste Änderungen in seinem Tagesrhythmus
                        vornehmen.
                        Zum Beispiel Schlafenszeiten, Frühstück, Mittagessen, Abendessen und andere Routinen.</p>
                </div>
                <div className="w-fit m-auto bg-main px-5 py-4 shadow-lg shadow-main rounded-lg max-w-[900px]">
                    <h2 className="inline-flex text-3xl font-bold items-center mb-2">Wie oft finden die Challenges
                        statt?</h2>
                    <p className="overflow-auto">Genaue Abstände zwischen den Challenges sind nicht vordefiniert. Es
                        wird
                        definitiv mehrere Challenges im Jahr geben. Sei gespannt und folge Veni auf allen Social Media
                        Kanälen. Links dazu findest Du unten auf dieser Seite in der Fußzeile.</p>
                </div>
                <div className="w-fit m-auto bg-main px-5 py-4 shadow-lg shadow-main rounded-lg max-w-[900px]">
                    <h2 className="inline-flex text-3xl font-bold items-center mb-2">Du hast weitere Fragen?</h2>
                    <p className="overflow-auto">Schreibe gerne eine Chatnachricht im Stream von Veni oder kommentiere
                        eines der "In 7 Tagen Pro werden" Videos auf YouTube. Ansonsten kannst Du auch gerne eine
                        Nachricht im <a className="text-primary underline" href="https://discord.gg/veni">Discord von
                            Veni</a> posten.</p>
                </div>
            </div>
            <BackgroundImage/>
        </div>
    );
}