import ChallengesTable from "../components/challenges-table";
import {ToastContainer} from "react-toastify";

import 'react-toastify/dist/ReactToastify.css';
import {useNavigate} from "react-router-dom";
import {useContext, useEffect} from "react";
import {TokenContext} from "../../App";

export default function Dashboard({challenges}) {
    const [token] = useContext(TokenContext);
    const navigate = useNavigate();

    useEffect(() => {
        if (!token) {
            navigate("/admin/login");
        }
    }, []);

    return (
        <div className="flex flex-col gap-10 mt-5 justify-self-center justify-center text-center">
            <ChallengesTable challenges={challenges}/>
            <ToastContainer/>
        </div>
    );
}

export const ToastOptions = {
    position: "bottom-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: false,
    progress: undefined,
    progressStyle: {backgroundColor: "#9134ff"},
    theme: "dark"
};
