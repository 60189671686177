import {BarChart3, PencilLine, Plus, Tag, Trash2, Youtube} from "lucide-react";
import {deleteChallenge} from "../../services/api";
import {toast} from "react-toastify";
import {ToastOptions} from "../pages/dashboard";
import Swal from 'sweetalert2';

import '@sweetalert2/theme-dark/dark.css';
import {Link, useNavigate} from "react-router-dom";

export default function ChallengesTable({challenges}) {
    const navigate = useNavigate();

    function deleteEntry(id) {
        Swal.fire({
            title: "Challenge löschen?",
            text: "Willst Du wirklich diese Challenge löschen? Dies kann nicht rückgängig gemacht werden!",
            icon: "warning",
            showCancelButton: true,
            color: "#ffffff",
            confirmButtonColor: "#4f00a6",
            cancelButtonColor: "#595959",
            confirmButtonText: "Challenge löschen",
            cancelButtonText: "Abbrechen"
        }).then((result) => {
            if (result.isConfirmed) {
                toast.promise(deleteChallenge(id), {
                    pending: "Challenge wird gelöscht...",
                    success: `Challenge wurde gelöscht.`,
                    error: `Challenge konnte nicht gelöscht werden.`,
                }, ToastOptions).then(r => {
                    navigate("/admin");
                });
            }
        });
    }

    return (
        <div className="flex justify-center w-full">
            <div id="admin-secret" className="absolute top-0 left-0 w-full h-full" hidden={true}>
                <img src="/img/veni_admin.png" className="w-full h-full" alt="Secret" onClick={toggleSecret}/>
            </div>
            <div className="flex flex-col bg-center bg-main w-[90%] px-5 py-3 shadow-lg shadow-main rounded-lg">
                <div className="grid grid-cols-2 mb-4">
                    <h2 className="text-2xl font-bold text-left select-none" onClick={toggleSecret}>Challenges</h2>
                    <Link to="/admin/challenge" className="mr-5 justify-self-end inline-flex px-3 py-2 bg-primary shadow-main rounded-lg
                                        select-none shadow-md hover:bg-hover transition-colors h-fit">
                        <Plus className="mr-3"/>
                        <span className="font-bold">Challenge hinzufügen</span>
                    </Link>
                </div>
                <table className="mt-4 table-fixed border-collapse">
                    <thead>
                    <tr className="border-b border-main">
                        <th className="w-1/3 font-medium p-4 pl-8 pt-0 pb-3 text-left">Name</th>
                        <th className="w-1/4 font-medium p-4 pl-8 pt-0 pb-3 text-left">Start</th>
                        <th className="w-1/4 font-medium p-4 pl-8 pt-0 pb-3 text-left">Ende</th>
                        <th className="font-medium p-4 pl-8 pt-0 pb-3">Editieren</th>
                        <th className="font-medium p-4 pl-8 pt-0 pb-3">Ränge</th>
                        <th className="font-medium p-4 pl-8 pt-0 pb-3">Videos</th>
                        <th className="font-medium p-4 pl-8 pt-0 pb-3">Namen</th>
                        <th className="font-medium p-4 pl-8 pt-0 pb-3">Löschen</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        challenges.sort((a, b) => b.startDate - a.startDate).map((challenge, index) => (
                            <tr key={index} className="text-left border-b border-main">
                                <td className="p-4 pl-8">{challenge.name}</td>
                                <td className="p-4 pl-8">{formatDate(challenge.startDate)}</td>
                                <td className="p-4 pl-8">{formatDate(challenge.endDate)}</td>
                                <td className="p-4 pl-8">
                                    <Link to={`/admin/challenge/${challenge.id}`} title="Editieren"><PencilLine
                                        className="w-full align-middle"/></Link>
                                </td>
                                <td className="p-4 pl-8">
                                    <Link to={`/admin/progress/${challenge.id}`} title="Ränge"><BarChart3
                                        className="w-full align-middle"/></Link>
                                </td>
                                <td className="p-4 pl-8">
                                    <Link to={`/admin/video/${challenge.id}`} title="Videos"><Youtube
                                        className="w-full align-middle"/></Link>
                                </td>
                                <td className="p-4 pl-8">
                                    <Link to={`/admin/sequence/${challenge.id}`} title="Rang-Namen"><Tag
                                        className="w-full align-middle"/></Link>
                                </td>
                                <td className="p-4 pl-8">
                                    <button title="Löschen" onClick={() => deleteEntry(challenge.id)}
                                            className="w-full">
                                        <Trash2 className="w-full align-middle"/></button>
                                </td>
                            </tr>
                        ))
                    }
                    </tbody>
                </table>
            </div>
        </div>
    );
}

function formatDate(dateTime) {
    return new Date(dateTime).toLocaleDateString("de-DE");
}

function toggleSecret() {
    const element = document.getElementById("admin-secret");
    element.hidden = !element.hidden;
}