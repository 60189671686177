import {useNavigate, useParams} from "react-router-dom";
import VideosTable from "../components/videos-table";
import {useContext, useEffect} from "react";
import {TokenContext} from "../../App";

export default function Videos({challenges}) {
    const {id} = useParams();
    const challenge = challenges.find((challenge) => challenge.id === Number.parseInt(id));
    const [token] = useContext(TokenContext);
    const navigate = useNavigate();

    useEffect(() => {
        if (!token) {
            navigate("/admin/login");
        }
    }, []);

    if (id && !challenge) {
        return (<></>);
    }

    return (
        <div className="flex flex-col gap-10 mt-5 justify-self-center justify-center text-center">
            <VideosTable challenge={challenge}/>
        </div>
    );
}