import {Link} from "react-router-dom";

export default function Footer() {
    return (
        <div className="text-secondary py-4 bg-main">
            <div className="grid grid-cols-2">
                <div className="flex flex-col justify-center w-full">
                    <div className="inline-flex justify-center">
                        <h3 className="font-bold text-lg">Rechtliches</h3>
                    </div>
                    <div className="inline-flex justify-center">
                        <Link to="/impressum">Impressum</Link>
                    </div>
                    <div className="inline-flex justify-center">
                        <Link to="/datenschutz">Datenschutzerklärung</Link>
                    </div>
                </div>
                <div className="flex flex-col justify-center w-full">
                    <div className="inline-flex justify-center">
                        <h3 className="font-bold text-lg">Social Media</h3>
                    </div>
                    <div className="inline-flex justify-center">
                        <a href="https://twitch.tv/Veni">Twitch</a>
                    </div>
                    <div className="inline-flex justify-center">
                        <a href="https://www.youtube.com/@VeniCraft">YouTube (Veni)</a>
                    </div>
                    <div className="inline-flex justify-center">
                        <a href="https://www.youtube.com/@venividivici3457">YouTube (VeniVidiVici)</a>
                    </div>
                    <div className="inline-flex justify-center">
                        <a href="https://twitter.com/venitroll">Twitter</a>
                    </div>
                </div>
            </div>
            <div className="justify-center w-full">
                <p>© Copyright Veni {new Date().getFullYear()} - All rights reserved.</p>
                <a href="https://twitch.tv/DerBanko">Developed by DerBanko</a>
            </div>
        </div>
    );
}