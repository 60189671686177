import {ToastContainer} from "react-toastify";

import 'react-toastify/dist/ReactToastify.css';
import LoginInput from "../components/login-input";

export default function Login() {
    return (
        <div className="flex flex-col gap-10 mt-5 justify-self-center justify-center text-center">
            <LoginInput/>
            <ToastContainer/>
        </div>
    );
}