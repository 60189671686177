import {useCookies} from "react-cookie";
import {Link2} from "lucide-react";

export default function Videos({videos, dontShowLastVideo = false}) {
    const [cookies, setCookie] = useCookies(["videos"]);

    if (dontShowLastVideo) videos = videos.slice(0, -1);

    console.log(videos)

    return (
        <div className="flex flex-wrap w-full px-2 justify-center gap-10">
            {videos.map((video) => (
                <div className="w-full videos-2:w-1/3 videos-3:w-1/4" key={"video-" + video.id}>
                    <div className="w-fit m-auto bg-main px-5 py-4 shadow-lg shadow-main rounded-lg">
                        <a className="inline-flex text-3xl font-bold items-center select-none"
                           href={`https://youtube.com/watch?v=${video.videoId}`}
                           title={"Zeige das Video auf YouTube an"}>{video.title} <Link2 className="ml-3"/></a>
                        {cookies.videos !== 1 ?
                            <div className="flex flex-col justify-center items-center pt-3 gap-3"><span>Bitte bestätige, dass Du das Video anzeigen möchtest.
                            Beim Klicken bestätigst Du die <a className="underline"
                                                              href="https://policies.google.com/privacy?hl=de">Datenschutzerklärung</a> {" "}
                                und <a className="underline"
                                       href="https://policies.google.com/terms?hl=de">Nutzungsbedingungen</a> von YouTube.
                            Deine Wahl wird in den Cookies gespeichert.</span>
                                <button className="bg-primary font-bold w-fit px-3 py-2 rounded-lg"
                                        onClick={() => setCookie("videos", 1)}>Video anzeigen
                                </button>
                            </div>
                            :
                            <iframe className="aspect-[16/9] w-full pt-3"
                                    title={video.videoId}
                                    height="360" width="640"
                                    src={`https://www.youtube-nocookie.com/embed/${video.videoId}`}></iframe>}
                    </div>
                </div>
            ))}
        </div>
    );
}