import {Plus, Tag, Trash2, Undo2} from "lucide-react";
import {useEffect, useState} from "react";
import {deleteSequence, getSequence, updateSequence} from "../../services/api";
import {toast} from "react-toastify";
import {ToastOptions} from "../pages/dashboard";
import {Link, useNavigate} from "react-router-dom";

export default function SequenceTable({challenge}) {
    const navigate = useNavigate();

    const [sequence, setSequence] = useState([]);
    const [toBeDeleted, setToBeDeleted] = useState([]);

    useEffect(() => {
        if (!challenge) {
            return;
        }

        getSequence(challenge.id).then(setSequence);
    }, [challenge]);

    function deleteName(event, element, index) {
        event.preventDefault();

        if (element.id) {
            setToBeDeleted(prevState => [...prevState, element]);
        }

        setSequence(prevState => prevState.filter((e, i) => index !== i));
    }

    function addName(event, challengeId) {
        event.preventDefault();

        if (event.target.disabled) {
            return;
        }

        const position = sequence.length;
        const name = "";

        setSequence(prevState => [...prevState, {challengeId, name, position}]);
    }

    function updateRank(event, element, index) {
        event.preventDefault();

        element.name = event.target.value;
        setSequence(prevState => prevState.map((e, i) => index === i ? element : e));
    }

    function submit() {
        const promise = updateSequence(sequence);

        toBeDeleted.forEach(element => {
            deleteSequence(element.id);
        });

        toast.promise(promise, {
            pending: "Namen werden gesetzt...",
            success: `Namen wurden gesetzt.`,
            error: `Namen konnten nicht geändert werden.`,
        }, ToastOptions).then(() => {
            navigate("/admin");
        });
    }

    return (
        <div className="flex justify-center w-full">
            <div className="flex flex-col gap-5 bg-center bg-main w-[90%] px-5 py-3 shadow-lg shadow-main rounded-lg">
                <div className="grid grid-cols-2 mb-4">
                    <h2 className="text-2xl font-bold text-left select-none">Rangnamen von {challenge?.name}</h2>
                    <button className="mr-5 justify-self-end inline-flex px-3 py-2 bg-primary shadow-main rounded-lg
                                        select-none shadow-md hover:bg-hover transition-colors h-fit"
                            onClick={event => addName(event, challenge.id)}>
                        <Plus className="mr-3"/>
                        <span className="font-bold">Rang hinzufügen</span>
                    </button>
                </div>
                <table className="mt-4 table-fixed border-collapse">
                    <thead>
                    <tr className="border-b border-main">
                        <th className="font-medium p-4 pl-8 pt-0 pb-3 text-left">Position</th>
                        <th className="font-medium p-4 pl-8 pt-0 pb-3 text-left w-full">Name</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        sequence.sort((a) => a.day).map((element, index) => (
                            <tr key={index} className="text-left border-b border-main">
                                <td className="p-4 pl-8">{element.position}</td>
                                <td className="p-4 pl-8">
                                    <input className="bg-main w-full border-b border-b-secondary border-opacity-75" value={element.name}
                                           onChange={(event) => updateRank(event, element, index)}/>
                                </td>
                                <td className="flex p-4 pl-8 justify-center">
                                    <button title="Rang entfernen"
                                            onClick={(event) => deleteName(event, element, index)}
                                            className="w-fit">
                                        <Trash2/></button>
                                </td>
                            </tr>
                        ))
                    }
                    </tbody>
                </table>
                <div className="flex flex-row">
                    <button className="mr-5 justify-self-end inline-flex px-3 py-2 bg-primary shadow-main rounded-lg
                                        select-none shadow-md hover:bg-hover transition-colors h-fit w-fit"
                            onClick={submit}>
                        <Tag className="mr-3"/><span className="font-bold">Namen setzen</span>
                    </button>
                    <Link to="/admin" className="mr-5 justify-self-end inline-flex px-3 py-2 bg-primary shadow-main rounded-lg
                                        select-none shadow-md hover:bg-hover transition-colors h-fit w-fit">
                        <Undo2 className="mr-3"/><span className="font-bold">Zurück</span>
                    </Link>
                </div>
            </div>
        </div>
    );
}
