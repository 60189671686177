export default function Header({name, description, startDate, endDate}) {
    const startDateString = new Date(startDate).toLocaleDateString("de-DE");
    const endDateString = new Date(endDate).toLocaleDateString("de-DE");

    return (
        <div className="flex flex-col w-fit m-auto -mt-10">
            <h1 className="text-6xl font-bold mb-2 text-primary font-title tracking-wide">{name}</h1>
            <h2 className="text-2xl font-bolder -mt-4">{name ? (startDateString + " — " + endDateString) : " "}</h2>
            <h3 className="text-lg break-words text-secondary">{description}</h3>
        </div>
    );
}