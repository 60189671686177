import {HelpCircle, History, PartyPopper} from 'lucide-react';
import {Link} from "react-router-dom";

export default function Navigation({currentChallenge, pastChallenges, history}) {
    return (
        <div className="mb-4">
            <div className="grid dropdown:grid-cols-3 grid-cols-2">
                {history ? <Link to="/" className="w-fit">
                        <img src={"/img/logo.svg"} alt="logo"
                             className="ml-5 aspect-square w-32 h-32 justify-self-start"/>
                    </Link> :
                    <a href="https://twitch.tv/Veni" className="w-fit">
                        <img src={"/img/logo.svg"} alt="logo"
                             className="ml-5 aspect-square w-32 h-32 justify-self-start"/>
                    </a>}
                <DropDown className="relative justify-center justify-self-center m-auto dropdown:block hidden"
                          currentChallenge={currentChallenge} pastChallenges={pastChallenges} history={history}/>
                <Link to="/faq" className="mr-5 justify-self-end inline-flex px-3 py-2 bg-primary shadow-main rounded-lg
                select-none shadow-lg hover:bg-hover transition-colors h-fit m-auto">
                    <HelpCircle className="mr-3"/>
                    <span className="font-bold">FAQ</span>
                </Link>
            </div>
            <DropDown className="relative justify-center justify-self-center m-auto dropdown:hidden block"
                      currentChallenge={currentChallenge} pastChallenges={pastChallenges} history={history}/>
        </div>
    );
}

function DropDown({className, currentChallenge, pastChallenges, history}) {
    return (
        <div className={className}>
            {pastChallenges.length > 0 &&
                (<>
                    <button
                        className="inline-flex px-5 py-2 bg-primary shadow-main rounded-lg select-none
                    shadow-lg hover:bg-hover transition-colors align-middle nav-button:min-w-[250px] mb-3 justify-center"
                        onClick={toggleDropdown}>
                        {history ? <PartyPopper className="mr-3"/> : <History className="mr-3"/>}
                        {history ? <span className="font-bold">Alle Challenges</span> :
                            <span className="font-bold">Vergangene Challenges</span>}
                    </button>
                    <div className="relative w-full hidden" id="navigation-dropdown">
                        <ul className="absolute w-full justify-self-center bg-primary shadow-lg shadow-main rounded-lg">
                            {pastChallenges.map((challenge) => {
                                const isCurrentChallenge = challenge === currentChallenge;
                                const href = (isCurrentChallenge) ? `/` : `/challenge/${challenge.id}`;
                                return (<li key={challenge.id} className="flex hover:bg-hover transition-colors
                                    last:rounded-b-lg first:rounded-t-lg border-b border-b-main last:border-b-0">
                                    <Link className="w-full h-full px-3 py-2"
                                          to={href}
                                          onClick={toggleDropdown}>{isCurrentChallenge ? "Zurück zu " : ""}<span
                                        className="font-bold">{challenge.name}</span></Link>
                                </li>);
                            })}
                        </ul>
                    </div>
                </>)
            }
        </div>
    );
}

function toggleDropdown() {
    const dropdown = document.getElementById("navigation-dropdown");
    dropdown.classList.toggle("hidden");
}