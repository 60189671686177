import {BarChart3, ChevronDown, ChevronUp, Plus, Trash2, Undo2} from "lucide-react";
import {useEffect, useState} from "react";
import {deleteProcess, getProgress, getSequence, updateProgress} from "../../services/api";
import {toast, ToastContainer} from "react-toastify";
import {ToastOptions} from "../pages/dashboard";
import {Link, useNavigate} from "react-router-dom";

import 'react-toastify/dist/ReactToastify.css';

export default function ProgressTable({challenge}) {
    const navigate = useNavigate();

    const [progress, setProgress] = useState([]);
    const [sequence, setSequence] = useState([]);
    const [toBeDeleted, setToBeDeleted] = useState([]);

    useEffect(() => {
        if (!challenge) {
            return;
        }

        fetchData();

        async function fetchData() {
            const progress = await getProgress(challenge.id);
            const sequence = await getSequence(challenge.id);

            setSequence(sequence);
            setProgress(progress);
        }
    }, [challenge]);

    function addRank(event, element, index) {
        event.preventDefault();

        if (event.target.disabled) {
            return;
        }

        element.position += 1;
        setProgress(prevState => prevState.map((e, i) => index === i ? element : e));
    }

    function removeRank(event, element, index) {
        event.preventDefault();

        if (event.target.disabled) {
            return;
        }

        element.position -= 1;
        setProgress(prevState => prevState.map((e, i) => index === i ? element : e));
    }

    function deleteRank(event, element, index) {
        event.preventDefault();

        if (element.id) {
            setToBeDeleted(prevState => [...prevState, element]);
        }

        setProgress(prevState => prevState.filter((e, i) => index !== i));
    }

    function addNextDay(event, challengeId) {
        event.preventDefault();

        if (sequence.length === 0) {
            toast("Es werden Rang-Namen benötigt, um Ränge zu erstellen.", ToastOptions);
            return;
        }

        const day = progress.length;
        const position = 0;

        setProgress(prevState => [...prevState, {challengeId, day, position}]);
    }

    function getRankName(element) {
        const sequenceElement = sequence.find(value => value.position === element.position);

        if (!sequenceElement) {
            return "⚠️ Kein Rangname vorhanden";
        }

        return sequenceElement.name;
    }

    function submit() {
        const promise = updateProgress(progress);

        toBeDeleted.forEach(element => {
            deleteProcess(element.id);
        });

        toast.promise(promise, {
            pending: "Ränge werden gesetzt...",
            success: `Ränge wurden gesetzt.`,
            error: `Ränge konnten nicht geändert werden.`,
        }, ToastOptions).then(r => {
            navigate("/admin");
        });
    }

    return (
        <div className="flex justify-center w-full">
            <div className="flex flex-col gap-5 bg-center bg-main w-[90%] px-5 py-3 shadow-lg shadow-main rounded-lg">
                <div className="grid grid-cols-2 mb-4">
                    <h2 className="text-2xl font-bold text-left select-none">Ränge von {challenge?.name}</h2>
                    <button className="mr-5 justify-self-end inline-flex px-3 py-2 bg-primary shadow-main rounded-lg
                                        select-none shadow-md hover:bg-hover transition-colors h-fit"
                            onClick={event => addNextDay(event, challenge.id)}>
                        <Plus className="mr-3"/>
                        <span className="font-bold">Nächster Tag hinzufügen</span>
                    </button>
                </div>
                <table className="mt-4 table-fixed border-collapse">
                    <thead>
                    <tr className="border-b border-main">
                        <th className="font-medium p-4 pl-8 pt-0 pb-3 text-left">Tag</th>
                        <th className="font-medium p-4 pl-8 pt-0 pb-3 text-left w-1/2">Rang</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        progress.sort((a) => a.day).map((element, index) => (
                            <tr key={index} className="text-left border-b border-main">
                                <td className="p-4 pl-8">Tag {element.day}</td>
                                <td className="p-4 pl-8">{getRankName(element)} ({element.position})</td>
                                <td className="p-4 pl-8 inline-flex gap-6">
                                    <button title="Rang aufstufen"
                                            onClick={(event) => addRank(event, element, index)}
                                            className="w-full disabled:opacity-25"
                                            disabled={(element.position + 1) >= sequence.length}>
                                        <ChevronUp className="w-full align-middle"/></button>
                                    <button title="Rang abstufen"
                                            onClick={(event) => removeRank(event, element, index)}
                                            className="w-full disabled:opacity-25"
                                            disabled={element.position === 0}>
                                        <ChevronDown className="w-full align-middle"/></button>
                                </td>
                                <td className="p-4 pl-8 inline-flex justify-center">
                                    <button title="Tag entfernen"
                                            onClick={(event) => deleteRank(event, element, index)}
                                            className="w-fit">
                                        <Trash2/></button>
                                </td>
                            </tr>
                        ))
                    }
                    </tbody>
                </table>
                <div className="flex flex-row">
                    <button className="mr-5 justify-self-end inline-flex px-3 py-2 bg-primary shadow-main rounded-lg
                                        select-none shadow-md hover:bg-hover transition-colors h-fit w-fit"
                            onClick={submit}>
                        <BarChart3 className="mr-3"/><span className="font-bold">Ränge setzen</span>
                    </button>
                    <Link to="/admin" className="mr-5 justify-self-end inline-flex px-3 py-2 bg-primary shadow-main rounded-lg
                                        select-none shadow-md hover:bg-hover transition-colors h-fit w-fit">
                        <Undo2 className="mr-3"/><span className="font-bold">Zurück</span>
                    </Link>
                </div>
            </div>
            <ToastContainer/>
        </div>
    );
}