export default function Stream() {
    return (
        <div className="mx-10">
            <div className="inline-flex w-full justify-center shadow-lg shadow-main">
                <iframe className="rounded-lg chat:rounded-r-none w-[100%] chat:w-[80%] aspect-[16/9] bg-primary"
                        title="Stream"
                        src={`https://player.twitch.tv/?channel=veni&parent=${window.location.hostname}`}
                        height="700"
                        width="100%"
                        allowFullScreen>
                </iframe>
                <iframe className="rounded-r-lg hidden chat:block bg-primary"
                        title="Chat"
                        src={`https://www.twitch.tv/embed/veni/chat?darkpopout&parent=${window.location.hostname}`}
                        height="700"
                        width="20%">
                </iframe>
            </div>
        </div>
    );
}