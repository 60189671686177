import Header from "../components/header";
import Stream from "../components/stream";
import CurrentRank from "../components/current-rank";
import Graph from "../components/graph";
import Videos from "../components/videos";
import Navigation from "../components/navigation";
import {getProgress, getSequence, getVideos} from "../../services/api";
import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import BackgroundImage from "../components/background-image";
import FinalVideo from "../components/final-video";

export default function ChallengePage({currentChallenge, challenges}) {
    const {id} = useParams();
    const navigate = useNavigate();

    const {challenge, pastChallenges} = getChallenges(currentChallenge, challenges, id);
    const history = currentChallenge !== challenge;

    const [progress, setProgress] = useState([]);
    const [sequence, setSequence] = useState([]);
    const [videos, setVideos] = useState([]);
    const [currentRankName, setCurrentRankName] = useState("Keine Rangeinteiltung");

    useEffect(() => {
        if (!challenge || !challenge.id) {
            return;
        }

        progress(challenge.id);
        getVideos(challenge.id).then(value => {
            setVideos(value.sort((a, b) => a.position - b.position));
        });

        async function progress(challengeId) {
            const progress = await getProgress(challengeId);
            const sequence = await getSequence(challengeId);

            if (sequence.length === 0) {
                setProgress([]);
                setCurrentRankName("Keine Rangeinteiltung");
                setSequence([]);
                return;
            }

            setProgress(progress);
            setCurrentRankName(progress.length > 0 ?
                sequence.find((rank) => rank.position === progress[progress.length - 1].position)?.name : "Kein Rang");
            setSequence(sequence);
        }
    }, [challenge]);

    if (!challenge) {
        if (id) {
            navigate("/");
            return (<></>);
        }

        return (
            <div className="flex flex-col gap-10 justify-self-center text-center">
                <Navigation currentChallenge={currentChallenge} pastChallenges={pastChallenges} history={history}/>
                <h1 className="text-3xl font-bold pb-5">Willkommen auf der <span className="text-primary">In 7 Tagen Pro werden</span> Website.
                </h1>
                <h2 className="text-2xl">Leider gibt es zum jetzigen Zeitpunkt noch nichts zu sehen, aber schau doch
                    gerne später wieder vorbei.</h2>
            </div>
        );
    }

    const {name, description, startDate, endDate} = challenge;
    const challengeDone = endDate < new Date().getTime();

    return (
        <div className="flex flex-col gap-10 justify-self-center text-center">
            <Navigation currentChallenge={currentChallenge} pastChallenges={pastChallenges} history={history}/>
            <Header name={name} description={description} startDate={startDate} endDate={endDate}/>
            {name &&
                <>
                    {challengeDone ? <FinalVideo videos={videos}/> : <Stream/>}
                    <CurrentRank rank={currentRankName} history={history}/>
                    <Graph ranks={progress} ranknames={sequence}/>
                    <Videos videos={videos} dontShowLastVideo={challengeDone}/>
                </>}
            <BackgroundImage/>
        </div>
    );

}

function getChallenges(currentChallenge, challenges, id) {
    const currentTime = new Date().getTime();
    let challenge = currentChallenge;

    if (id) {
        challenge = challenges.find(value => Number.parseInt(id) === value.id);
    }

    let pastChallenges = challenges.filter(value => value.startDate < currentTime);

    if (currentChallenge && !pastChallenges.includes(currentChallenge)) {
        pastChallenges.push(currentChallenge);
    }

    if (challenge) {
        pastChallenges = pastChallenges.filter(value => value.id !== challenge.id);
    }

    return {challenge, pastChallenges};
}