import {Plus, Trash2, Undo2, Youtube} from "lucide-react";
import {useEffect, useState} from "react";
import {getVideos, updateVideos} from "../../services/api";
import {toast, ToastContainer} from "react-toastify";
import {ToastOptions} from "../pages/dashboard";
import {Link, useNavigate} from "react-router-dom";

import 'react-toastify/dist/ReactToastify.css';

const youtubeRegex = /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})$/;

export default function VideosTable({challenge}) {
    const navigate = useNavigate();
    const [videos, setVideos] = useState([]);
    const [toBeDeleted, setToBeDeleted] = useState([]);

    useEffect(() => {
        if (!challenge) {
            return;
        }

        getVideos(challenge.id).then(setVideos);
    }, [challenge]);

    function deleteVideo(event, element, index) {
        event.preventDefault();

        if (element.id) {
            setToBeDeleted(prevState => [...prevState, element]);
        }

        setVideos(prevState => prevState.filter((e, i) => index !== i));
    }

    function addVideo(event, challengeId) {
        event.preventDefault();

        if (event.target.disabled) {
            return;
        }

        const position = videos.length + 1;
        const videoId = "";
        const title = "";

        setVideos(prevState => [...prevState, {challengeId, videoId, title, position}]);
    }

    function updateVideoTitle(event, element, index) {
        event.preventDefault();

        element.title = event.target.value;
        setVideos(prevState => prevState.map((e, i) => index === i ? element : e));
    }

    function updateVideoId(event, element, index) {
        event.preventDefault();

        const match = event.target.value.split("&")[0].match(youtubeRegex);

        if (!match) {
            toast("Ungültiger YouTube Link", ToastOptions);
            return;
        }

        element.videoId = match[1];
        setVideos(prevState => prevState.map((e, i) => index === i ? element : e));
    }

    function submit() {
        const promise = updateVideos(videos);

        toBeDeleted.forEach(element => {
            deleteVideo(element.id);
        });

        toast.promise(promise, {
            pending: "Videos werden gesetzt...",
            success: `Videos wurden gesetzt.`,
            error: `Videos konnten nicht geändert werden.`,
        }, ToastOptions).then(() => {
            navigate("/admin");
        });
    }

    return (
        <div className="flex justify-center w-full">
            <div className="flex flex-col gap-5 bg-center bg-main w-[90%] px-5 py-3 shadow-lg shadow-main rounded-lg">
                <div className="grid grid-cols-2 mb-4">
                    <h2 className="text-2xl font-bold text-left select-none">Videos von {challenge?.name}</h2>
                    <button className="mr-5 justify-self-end inline-flex px-3 py-2 bg-primary shadow-main rounded-lg
                                        select-none shadow-md hover:bg-hover transition-colors h-fit"
                            onClick={event => addVideo(event, challenge.id)}>
                        <Plus className="mr-3"/>
                        <span className="font-bold">Video hinzufügen</span>
                    </button>
                </div>
                <table className="mt-4 table-fixed border-collapse">
                    <thead>
                    <tr className="border-b border-main">
                        <th className="font-medium p-4 pl-8 pt-0 pb-3 text-left">Position</th>
                        <th className="font-medium p-4 pl-8 pt-0 pb-3 text-left w-1/2">YouTube ID</th>
                        <th className="font-medium p-4 pl-8 pt-0 pb-3 text-left w-1/2">Titel</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        videos.sort((a) => a.day).map((element, index) => (
                            <tr key={index} className="text-left border-b border-main">
                                <td className="p-4 pl-8">{element.position}</td>
                                <td className="p-4 pl-8">
                                    <input className="bg-main w-full border-b border-b-secondary border-opacity-75" value={element.videoId}
                                           onChange={(event) => updateVideoId(event, element, index)}
                                            onCopy={(event) => {
                                                event.preventDefault();
                                                navigator.clipboard.writeText(`https://www.youtube.com/watch?v=${element.videoId}`);
                                            }}/>
                                </td>
                                <td className="p-4 pl-8">
                                    <input className="bg-main w-full border-b border-b-secondary border-opacity-75" value={element.title}
                                           onChange={(event) => updateVideoTitle(event, element, index)}/>
                                </td>
                                <td className="flex p-4 pl-8 justify-center">
                                    <button title="Rang entfernen"
                                            onClick={(event) => deleteVideo(event, element, index)}
                                            className="w-fit">
                                        <Trash2/></button>
                                </td>
                            </tr>
                        ))
                    }
                    </tbody>
                </table>
                <div className="flex flex-row">
                    <button className="mr-5 justify-self-end inline-flex px-3 py-2 bg-primary shadow-main rounded-lg
                                        select-none shadow-md hover:bg-hover transition-colors h-fit w-fit"
                            onClick={submit}>
                        <Youtube className="mr-3"/><span className="font-bold">Videos setzen</span>
                    </button>
                    <Link to="/admin" className="mr-5 justify-self-end inline-flex px-3 py-2 bg-primary shadow-main rounded-lg
                                        select-none shadow-md hover:bg-hover transition-colors h-fit w-fit">
                        <Undo2 className="mr-3"/><span className="font-bold">Zurück</span>
                    </Link>
                </div>
            </div>
            <ToastContainer/>
        </div>
    );
}
