import Navigation from "../components/navigation";
import BackgroundImage from "../components/background-image";

export default function Imprint({currentChallenge, challenges}) {
    const currentTime = new Date().getTime();

    let pastChallenges = challenges.filter(value => value.startDate < currentTime);

    if (!pastChallenges.includes(currentChallenge)) {
        pastChallenges.push(currentChallenge);
    }

    return (
        <div className="flex flex-col gap-10 justify-self-center text-center">
            <Navigation currentChallenge={currentChallenge} pastChallenges={pastChallenges} history={true}/>
            <div className="w-fit max-w-[90%] m-auto bg-main px-5 py-4 shadow-lg shadow-main rounded-lg -mt-10">
                <div className="flex flex-col m-auto w-full max-w-[900px] gap-4 px-5 py-4">
                    <h1 className="text-6xl font-bold mb-2 text-primary font-title tracking-wide leading-7">Impressum</h1>
                    <h2 className="inline-flex text-3xl font-bold items-center mb-2">Informationspflicht laut §5
                        E-Commerce Gesetz, §14 Unternehmensgesetzbuch, §63 Gewerbeordnung und Offenlegungspflicht
                        laut §25 Mediengesetz.</h2>
                    <p className="overflow-auto">
                        Rafael Eisler<br/>
                        c/o Kartell07<br/>
                        Arbeitergasse 7,<br/>
                        1050 Wien,<br/>
                        Österreich
                    </p>
                    <p className="overflow-auto">
                        Unternehmensgegenstand: Multimedia Agentur<br/>
                        UID-Nummer: ATU70335069
                    </p>
                    <p className="overflow-auto">
                        E-Mail: <a className="underline"
                                   href="mailto://business@venicraft.at">business@venicraft.at</a>
                    </p>
                    <p className="overflow-auto">
                        Mitglied bei: WKW<br/>
                        Berufsrecht: Gewerbeordnung: www.ris.bka.gv.at
                    </p>
                    <p className="overflow-auto">Berufsbezeichnung: Multimedia Agentur<br/>
                        Verleihungsstaat: Österreich</p>
                </div>
            </div>
            <BackgroundImage/>
        </div>
    );
}