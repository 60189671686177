const API_URL = "https://7tage.pro/api";

export async function createChallenge(name, description, startDate, endDate) {
    const response = await fetch(API_URL + "/challenge", {
        method: "POST",
        credentials: "include",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + sessionStorage.getItem("token")
        },
        body: JSON.stringify({
            name,
            description,
            startDate,
            endDate
        })
    });
    return await response.json();
}

export async function updateChallenge(id, name, description, startDate, endDate) {
    const response = await fetch(API_URL + "/challenge", {
        method: "PUT",
        credentials: "include",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + sessionStorage.getItem("token")
        },
        body: JSON.stringify({
            id,
            name,
            description,
            startDate,
            endDate
        })
    });
    return await response.json();
}

export async function deleteChallenge(id) {
    const response = await fetch(API_URL + "/challenge/" + id, {
        method: "DELETE",
        credentials: "include",
        headers: {
            "Authorization": "Bearer " + sessionStorage.getItem("token")
        }
    });
    return await response.json();
}

export async function getChallenges() {
    const response = await fetch(API_URL + "/challenge");
    return await response.json();
}

export async function getProgress(challengeId) {
    const response = await fetch(API_URL + "/progress/" + challengeId);
    return (await response.json()).sort((a, b) => a.day - b.day);
}

export async function updateProgress(progress) {
    const response = await fetch(API_URL + "/progress", {
        method: "POST",
        credentials: "include",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + sessionStorage.getItem("token")
        },
        body: JSON.stringify(progress)
    });
    return await response.json();
}

export async function deleteProcess(id) {
    const response = await fetch(API_URL + "/progress/" + id, {
        method: "DELETE",
        credentials: "include",
        headers: {
            "Authorization": "Bearer " + sessionStorage.getItem("token")
        }
    });
    return await response.json();
}

export async function getSequence(challengeId) {
    const response = await fetch(API_URL + "/sequence/" + challengeId);
    return (await response.json()).sort((a, b) => a.position - b.position);
}

export async function updateSequence(sequence) {
    const response = await fetch(API_URL + "/sequence", {
        method: "POST",
        credentials: "include",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + sessionStorage.getItem("token")
        },
        body: JSON.stringify(sequence)
    });
    return await response.json();
}

export async function deleteSequence(id) {
    const response = await fetch(API_URL + "/sequence/" + id, {
        method: "DELETE",
        credentials: "include",
        headers: {
            "Authorization": "Bearer " + sessionStorage.getItem("token")
        }
    });
    return await response.json();
}

export async function getVideos(challengeId) {
    const response = await fetch(API_URL + "/video/" + challengeId);
    return (await response.json()).sort((a, b) => a.position - b.position);
}

export async function updateVideos(videos) {
    const response = await fetch(API_URL + "/video", {
        method: "POST",
        credentials: "include",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + sessionStorage.getItem("token")
        },
        body: JSON.stringify(videos)
    });
    return await response.json();
}

export async function deleteVideo(id) {
    const response = await fetch(API_URL + "/video/" + id, {
        method: "DELETE",
        credentials: "include",
        headers: {
            "Authorization": "Bearer " + sessionStorage.getItem("token")
        },
    });
    return await response.json();
}

export async function login(username, password) {
    const response = await fetch(API_URL + "/admin/login", {
        method: "GET",
        credentials: "include",
        headers: {
            "Authorization": "Basic " + btoa(username + ":" + password)
        }
    });

    if (response.status !== 200) {
        return null;
    }

    return await response.json();
}

export async function validate(token) {
    const response = await fetch(API_URL + "/admin/validate", {
        method: "GET",
        credentials: "include",
        headers: {
            "Authorization": "Bearer " + token
        }
    });
    return response.status === 200;
}