import ChallengePage from "./public/pages/challenge-page";
import {Route, Routes, useLocation, useNavigate} from "react-router-dom";
import Imprint from "./public/pages/imprint";
import DataProtection from "./public/pages/data-protection";
import FAQ from "./public/pages/faq";
import {getChallenges, validate} from "./services/api";
import {createContext, useEffect, useState} from "react";
import Dashboard from "./admin/pages/dashboard";
import Challenge from "./admin/pages/challenge";
import Progress from "./admin/pages/progress";
import Sequence from "./admin/pages/sequence";
import Videos from "./admin/pages/videos";
import Login from "./admin/pages/login";
import Footer from "./public/components/footer";

export const TokenContext = createContext([]);

function App() {
    const location = useLocation();
    const navigate = useNavigate();

    let tokenState = useState(sessionStorage.getItem("token"));
    let [challenges, setChallenges] = useState([]);
    let [currentChallenge, setCurrentChallenge] = useState([]);
    const [token, setToken] = tokenState;

    useEffect(() => {
        if (!token) {
            sessionStorage.removeItem("token");
            return;
        }

        sessionStorage.setItem("token", token);
    }, [token]);

    useEffect(() => {
        if (token) {
            validate(token).then((valid) => {
                if (valid) {
                    return;
                }

                navigate("/admin/login");
                setToken(undefined);
            });
        }

        getChallenges().then(responseChallenges => {
            const currentTime = new Date().getTime();

            let currentChallenge = responseChallenges.find(value => value.startDate <= currentTime && currentTime <= value.endDate);

            if (!currentChallenge) {
                currentChallenge = responseChallenges.sort((a, b) => a.endDate - b.endDate)
                    .find(value => value.startDate > currentTime);

                if (!currentChallenge) {
                    currentChallenge = responseChallenges.sort((a, b) => b.endDate - a.endDate)[0];
                }
            }

            setChallenges(responseChallenges.sort((a, b) => b.endDate - a.endDate));
            setCurrentChallenge(currentChallenge);
        });
    }, [location.key]);

    return (
        <div className="flex flex-col w-full text-center min-h-[100vh]">
            <div className="grow pb-10">
                <TokenContext.Provider value={tokenState}>
                    <Routes>
                        <Route path="/"
                               element={<ChallengePage currentChallenge={currentChallenge} challenges={challenges}/>}/>
                        <Route path="/challenge/:id"
                               element={<ChallengePage currentChallenge={currentChallenge} challenges={challenges}/>}/>
                        <Route path="/impressum"
                               element={<Imprint currentChallenge={currentChallenge} challenges={challenges}/>}/>
                        <Route path="/datenschutz"
                               element={<DataProtection currentChallenge={currentChallenge} challenges={challenges}/>}/>
                        <Route path="/faq"
                               element={<FAQ currentChallenge={currentChallenge} challenges={challenges}/>}/>
                        <Route path="/admin" element={<Dashboard challenges={challenges}/>}/>
                        <Route path="/admin/challenge/" element={<Challenge challenges={challenges}/>}/>
                        <Route path="/admin/challenge/:id" element={<Challenge challenges={challenges}/>}/>
                        <Route path="/admin/progress/:id" element={<Progress challenges={challenges}/>}/>
                        <Route path="/admin/sequence/:id" element={<Sequence challenges={challenges}/>}/>
                        <Route path="/admin/video/:id" element={<Videos challenges={challenges}/>}/>
                        <Route path="/admin/login" element={<Login/>}/>
                    </Routes>
                </TokenContext.Provider>
            </div>
            <Footer/>
        </div>
    );
}

export default App;
