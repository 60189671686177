import {Undo2} from "lucide-react";
import {useContext, useState} from "react";
import {login, validate} from "../../services/api";
import {toast, ToastContainer} from "react-toastify";
import {ToastOptions} from "../pages/dashboard";
import {Link, useNavigate} from "react-router-dom";
import {TokenContext} from "../../App";

export default function LoginInput() {
    const navigate = useNavigate();
    const [token, setToken] = useContext(TokenContext);

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    function submit() {
        login(username, password)
            .then((value) => {
                if (!value) {
                    toast("Login fehlgeschlagen", ToastOptions);
                    return;
                }

                setToken(value.token);
                toast("Login erfolgreich", ToastOptions);
                navigate("/admin");
            })
            .catch(reason => {
                console.log(reason);
                toast("Login fehlgeschlagen", ToastOptions);
            });
    }

    if (token) {
        validate(token).then((valid) => {
            if (!valid) {
                return;
            }

            navigate("/admin");
        })
        return <></>;
    }

    return (
        <div className="flex justify-center align-middle w-full">
            <div className="flex flex-col gap-5 bg-center bg-main w-[90%] px-5 py-5 shadow-lg shadow-main rounded-lg">
                <h2 className="text-2xl font-bold text-left select-none">Login</h2>
                <div className="flex flex-col gap-5 justify-start w-full">
                    <div className="flex w-full items-center">
                        <label htmlFor="name" className="text-lg font-bold pr-3">Benutzername</label>
                        <input id="name" type="text"
                               value={username}
                               onChange={(event) => setUsername(event.target.value)}
                               className="px-3 py-2 bg-primary shadow-main rounded-lg
                                         shadow-md hover:bg-hover transition-colors h-fit"/>
                    </div>
                    <div className="flex w-full items-center">
                        <label htmlFor="password" className="text-lg font-bold pr-3">Passwort</label>
                        <input id="password" type="password"
                               value={password}
                               onChange={(event) => setPassword(event.target.value)}
                               className="px-3 py-2 bg-primary shadow-main rounded-lg
                                         shadow-md hover:bg-hover transition-colors h-fit"/>
                    </div>
                </div>
                <div className="flex flex-row">
                    <button className="mr-5 justify-self-end inline-flex px-3 py-2 bg-primary shadow-main rounded-lg
                                        select-none shadow-md hover:bg-hover transition-colors h-fit w-fit"
                            onClick={submit}
                            type="submit">
                        <span className="font-bold">Login</span>
                    </button>
                    <Link to="/" className="mr-5 justify-self-end inline-flex px-3 py-2 bg-primary shadow-main rounded-lg
                                        select-none shadow-md hover:bg-hover transition-colors h-fit w-fit">
                        <Undo2 className="mr-3"/><span className="font-bold">Zur Homepage</span>
                    </Link>
                </div>
            </div>
            <ToastContainer/>
        </div>
    );
}
