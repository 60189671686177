import {PencilLine, Plus, Undo2} from "lucide-react";
import {useState} from "react";
import {createChallenge, updateChallenge} from "../../services/api";
import {toast, ToastContainer} from "react-toastify";
import {ToastOptions} from "../pages/dashboard";
import {Link, useNavigate} from "react-router-dom";

export default function ChallengeInput({
                                           id, name = "", description = "",
                                           startDate = 0, endDate = 0
                                       }) {
    const navigate = useNavigate();

    const [tempName, setTempName] = useState(name);
    const [tempDescription, setTempDescription] = useState(description);
    const [tempStartDate, setTempStartDate] = useState(formatDate(startDate));
    const [tempEndDate, setTempEndDate] = useState(formatDate(endDate));

    function submit() {
        const startDate = stripTime(new Date(tempStartDate).getTime());
        const endDate = stripTime(new Date(tempEndDate).getTime()) + (24 * 60 * 60 * 1000) - 1;

        if (id) {
            const promise = updateChallenge(id, tempName, tempDescription, startDate, endDate);

            toast.promise(promise, {
                pending: "Challenge wird geupdatet...",
                success: `Challenge wurde geupdatet.`,
                error: `Challenge konnte nicht geupdatet werden.`,
            }, ToastOptions).then(() => {
                navigate("/admin");
            });
            return;
        }

        const promise = createChallenge(tempName, tempDescription, startDate, endDate);

        toast.promise(promise, {
            pending: "Challenge wird erstellt...",
            success: `Challenge wurde erstellt.`,
            error: `Challenge konnte nicht erstellt werden.`,
        }, ToastOptions).then(() => {
            navigate("/admin");
        });
    }

    return (
        <div className="flex justify-center w-full">
            <div className="flex flex-col gap-5 bg-center bg-main w-[90%] px-5 py-5 shadow-lg shadow-main rounded-lg">
                <h2 className="text-2xl font-bold text-left select-none">{id ? "Challenge editieren" : "Challenge hinzufügen"}</h2>
                <div className="flex flex-col gap-5 justify-start w-full">
                    <div className="flex w-full items-center">
                        <label htmlFor="name" className="text-lg font-bold pr-3">Name</label>
                        <input id="name" type="text"
                               value={tempName}
                               onChange={(event) => setTempName(event.target.value)}
                               className="px-3 py-2 bg-primary shadow-main rounded-lg
                                         shadow-md hover:bg-hover transition-colors h-fit"/>
                    </div>
                    <div className="flex w-full items-center">
                        <label htmlFor="description" className="text-lg font-bold pr-3">Beschreibung</label>
                        <textarea id="description"
                                  value={tempDescription}
                                  onChange={(event) => setTempDescription(event.target.value)}
                                  className="px-3 py-2 bg-primary shadow-main rounded-lg
                                         shadow-md hover:bg-hover transition-colors h-fit"/>
                    </div>
                    <div className="flex w-full items-center">
                        <label htmlFor="startDate" className="text-lg font-bold pr-3">Start</label>
                        <input id="startDate" type="date"
                               value={tempStartDate}
                               onChange={(event) => setTempStartDate(event.target.value)}
                               className="px-3 py-2 bg-primary shadow-main rounded-lg
                                         shadow-md hover:bg-hover transition-colors h-fit"/>
                    </div>
                    <div className="flex w-full items-center">
                        <label htmlFor="endDate" className="text-lg font-bold pr-3">Ende</label>
                        <input id="endDate" type="date"
                               value={tempEndDate}
                               onChange={(event) =>
                                   setTempEndDate(event.target.value)}
                               className="px-3 py-2 bg-primary shadow-main rounded-lg
                                         shadow-md hover:bg-hover transition-colors h-fit"/>
                    </div>
                </div>
                <div className="flex flex-row">
                    <button className="mr-5 justify-self-end inline-flex px-3 py-2 bg-primary shadow-main rounded-lg
                                        select-none shadow-md hover:bg-hover transition-colors h-fit w-fit"
                            onClick={submit}>
                        {id ? <PencilLine className="mr-3"/> : <Plus className="mr-3"/>}
                        {id ? <span className="font-bold">Challenge editieren</span> :
                            <span className="font-bold">Challenge hinzufügen</span>}
                    </button>
                    <Link to="/admin" className="mr-5 justify-self-end inline-flex px-3 py-2 bg-primary shadow-main rounded-lg
                                        select-none shadow-md hover:bg-hover transition-colors h-fit w-fit">
                        <Undo2 className="mr-3"/><span className="font-bold">Zurück</span>
                    </Link>
                </div>
            </div>
            <ToastContainer/>
        </div>
    );
}

function stripTime(dateTime) {
    const date = new Date(dateTime);
    return new Date(date.getFullYear(), date.getMonth(), date.getDate()).getTime();
}

function formatDate(dateTime) {
    if (dateTime === 0) {
        dateTime = new Date().getTime();
    }

    const date = new Date(dateTime);
    const month = date.getMonth() + 1;
    const day = date.getDate();

    return date.getFullYear() + "-" + (month < 10 ? "0" : "") + month + "-" + (day < 10 ? "0" : "") + day;
}
