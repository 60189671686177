import Navigation from "../components/navigation";
import BackgroundImage from "../components/background-image";

export default function DataProtection({currentChallenge, challenges}) {
    const currentTime = new Date().getTime();

    let pastChallenges = challenges.filter(value => value.startDate < currentTime);

    if (!pastChallenges.includes(currentChallenge)) {
        pastChallenges.push(currentChallenge);
    }

    return (
        <div className="flex flex-col gap-10 justify-self-center text-center">
            <Navigation currentChallenge={currentChallenge} pastChallenges={pastChallenges} history={true}/>
            <div className="w-fit max-w-[90%] m-auto bg-main px-5 py-4 shadow-lg shadow-main rounded-lg -mt-10">
                <div className="flex flex-col gap-5 px-5 py-4">
                    <h1 className="text-6xl font-bold mb-2 text-primary font-title tracking-wide leading-7">Datenschutzerklärung</h1>
                    <p>Wir nehmen den Schutz Ihrer persönlichen Daten sehr ernst. Wir halten uns an die Regeln der
                        geltenden
                        Datenschutzgesetze. Personenbezogene Daten, die auf der Webseite erhoben werden, sind technisch
                        notwendig.
                        Die nachfolgende Erklärung gibt Ihnen einen Überblick darüber, wie wir diesen Schutz
                        gewährleisten
                        und welche Art von Daten zu welchem Zweck erhoben werden.</p>
                    <div>
                        <h2 className="text-xl font-bold">1. Verantwortlicher</h2>
                        <p>Name und Kontaktdaten des Verantwortlichen finden Sie im Impressum der Webseite.</p>
                    </div>
                    <div>
                        <h2 className="text-xl font-bold">2. Hosting und Zugriffsdaten</h2>
                        <p>Wir hosten diese Webseite bei Hetzner und verwenden die DNS-Dienste von Cloudflare. Bei jedem
                            Zugriff
                            auf Inhalte dieses Internetangebotes werden Daten über den Vorgang temporär in einem
                            Protokoll
                            gespeichert und verarbeitet.</p>
                    </div>
                    <div>
                        <h2 className="text-xl font-bold">3. Einbettungen und externe Dienste</h2>
                        <h3 className="text-lg font-bold pt-2">YouTube</h3>
                        <p>Wir verwenden auf unserer Website Plugins der Website YouTube. Betreiber dieser Seiten ist
                            die
                            Google Ireland Limited. Bevor jedoch eine Verbindung mit den Servern von YouTube hergestellt
                            wird, müssen Sie dies auf der Webseite, welche Plugins von YouTube verwendet, bestätigen.
                            Danach wird dem YouTube-Server mitgeteilt, welche unserer Seiten Sie besucht haben. Die
                            Datenschutzerklärung von YouTube finden Sie{" "}
                            <a className="text-secondary underline"
                               href="https://policies.google.com/privacy">hier</a>.
                        </p>
                        <h3 className="text-lg font-bold pt-2">Twitch</h3>
                        <p>Wir binden den Livestream und den Chat des Kanals Veni von Twitch ein. Bei Nutzung dieser
                            Dienste werden Daten an Twitch übermittelt. Diese Daten können u.a. Ihre IP-Adresse,
                            Browser-Typ, Betriebssystem und Informationen über Ihre Interaktionen mit Twitch-Inhalten
                            beinhalten. Die Datenschutzerklärung von Twitch finden Sie{" "}
                            <a className="text-secondary underline"
                               href="https://twitter.com/de/privacy">hier</a>.
                        </p>
                    </div>
                    <div>
                        <h2 className="text-xl font-bold">4. Ihre Rechte</h2>
                        <p>Sie haben das Recht, Auskunft über die bezüglich Ihrer Person gespeicherten Daten, deren
                            Herkunft
                            und Empfänger, und den Zweck der Speicherung zu erhalten.</p>
                    </div>
                    <div>
                        <p>Für weitere Informationen und Anfragen kontaktieren Sie uns bitte über die im Impressum
                            angegebenen Kontaktdaten.</p>
                    </div>
                </div>
            </div>
            <BackgroundImage/>
        </div>
    );
}